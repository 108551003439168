import React, { useEffect, useState, useRef } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "./ArticleEditor.css";
import config from "../../config.json";
import axios from "axios";

const ArticleEditor = () => {
  const [articles, setArticles] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [title, setTitle] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const quillRef = useRef(null);
  const previewImageRef = useRef(null);

  useEffect(() => {
    if (!quillRef.current) {
      quillRef.current = new Quill("#editor", {
        theme: "snow",
        placeholder: "Начните редактирование...",
        modules: {
          toolbar: {
            container: [
              [{ header: [1, 2, false] }],
              ["bold", "italic", "underline"],
              ["image", "blockquote"],
              [{ list: "ordered" }, { list: "bullet" }],
              ["clean"],
            ],
            handlers: {
              image: handleQuillImageUpload,
            },
          },
        },
      });

      enableImageResize();
    }
    loadArticles();
  }, []);

  const loadArticles = async () => {
    try {
      const response = await axios.get(`${config.service}/api/articles`);
      setArticles(response.data);
    } catch (error) {
      console.error("Ошибка загрузки статей:", error);
    }
  };

  const handleArticleSelect = async (event) => {
    const articleId = event.target.value;
    if (!articleId) {
      setSelectedArticleId(null);
      setTitle("");
      setImageBase64("");
      quillRef.current.root.innerHTML = "";
      return;
    }

    try {
      const response = await axios.get(`${config.service}/api/articles/${articleId}`);
      const article = response.data;
      setSelectedArticleId(articleId);
      setTitle(article.title);
      setImageBase64(article.image || "");
      quillRef.current.root.innerHTML = article.content;
    } catch (error) {
      console.error("Ошибка загрузки статьи:", error);
    }
  };

  const compressImage = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const compressedBase64 = await compressImage(file);
      setImageBase64(compressedBase64);
      setTimeout(enablePreviewImageResize, 100);
    } catch (error) {
      console.error("Ошибка сжатия изображения:", error);
    }
  };

  const handleRemovePreview = () => {
    setImageBase64("");
  };

  const handleQuillImageUpload = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;

      try {
        const compressedBase64 = await compressImage(file);
        const range = quillRef.current.getSelection();
        quillRef.current.insertEmbed(range.index, "image", compressedBase64);
        enableImageResize();
      } catch (error) {
        console.error("Ошибка сжатия изображения:", error);
      }
    };
  };

  const enableImageResize = () => {
    setTimeout(() => {
      const images = document.querySelectorAll(".ql-editor img");
      images.forEach((img) => {
        img.style.cursor = "nwse-resize";
        img.addEventListener("mousedown", startResize);
      });
    }, 500);
  };

  const enablePreviewImageResize = () => {
    if (previewImageRef.current) {
      previewImageRef.current.style.cursor = "nwse-resize";
      previewImageRef.current.addEventListener("mousedown", startResize);
    }
  };

  const startResize = (event) => {
    const img = event.target;
    const startX = event.clientX;
    const startWidth = img.width;

    const onMouseMove = (moveEvent) => {
      const newWidth = startWidth + (moveEvent.clientX - startX);
      img.style.width = newWidth + "px";
    };

    const onMouseUp = () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("mouseup", onMouseUp);
    };

    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("mouseup", onMouseUp);
  };

  const saveArticle = async () => {
    const newArticle = {
      title: title.trim(),
      image: imageBase64,
      content: quillRef.current.root.innerHTML.trim(),
    };

    try {
      if (selectedArticleId) {
        await axios.put(`${config.service}/api/articles/${selectedArticleId}`, newArticle);
      } else {
        await axios.post(`${config.service}/api/articles`, newArticle);
      }

      alert("Статья сохранена!");
      loadArticles();
    } catch (error) {
      console.error("Ошибка сохранения:", error.response ? error.response.data : error);
    }
  };

  const deleteArticle = async () => {
    if (!selectedArticleId) {
      alert("Выберите статью для удаления.");
      return;
    }

    try {
      await axios.delete(`${config.service}/api/articles/${selectedArticleId}`);
      alert("Статья удалена!");
      setSelectedArticleId(null);
      setTitle("");
      setImageBase64("");
      quillRef.current.root.innerHTML = "";
      loadArticles();
    } catch (error) {
      console.error("Ошибка удаления статьи:", error);
    }
  };

  return (
    <div className="article-editor-container">
      <h1>Редактор статей</h1>

      <div className="article-editor-select-container">
        <label htmlFor="articles-list">Выберите статью:</label>
        <select id="articles-list" onChange={handleArticleSelect}>
          <option value="">Новая статья</option>
          {articles.map((article) => (
            <option key={article._id} value={article._id}>
              {article.title}
            </option>
          ))}
        </select>
      </div>

      <input
        type="text"
        className="article-editor-input"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Заголовок"
      />

      <div className="article-editor-image-upload">
        <input type="file" onChange={handleImageUpload} />
        {imageBase64 && (
          <div className="article-editor-image-preview">
            <img ref={previewImageRef} src={imageBase64} alt="Превью" />
            <button onClick={handleRemovePreview} className="article-editor-remove-button">
              Удалить
            </button>
          </div>
        )}
      </div>

      <div id="editor"></div>

      <div className="article-editor-button-container">
        <button onClick={saveArticle} className="article-editor-save-button">Сохранить</button>
        {selectedArticleId && (
          <button onClick={deleteArticle} className="article-editor-delete-button">Удалить</button>
        )}
      </div>
    </div>
  );
};

export default ArticleEditor;