import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Form,
  Pagination,
  Table,
  message,
  Tooltip,
  Select,
  Segmented,
} from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { Input } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  useParams,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import config from "../../config.json";
import { ProductOrders } from "./components/ProductOrders";
import { clearToken } from "../../scripts/token";
import { currencyFormat } from "../../scripts/format";
import { PriceHistory } from "./components/PriceHistory";
import { ProductRemains } from "./components/ProductRemains";
import { ProductCities } from "./components/ProductCities";
import { AutoAcceptOrderSettings } from "./components/AutoAcceptOrderSettings";
import { ProductStoreInfo } from "./components/ProductStoreInfo";

import {
  LoadingOutlined,
  WarningOutlined,
  InboxOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import ChangePriceRequestResult from "./components/ChangePriceRequestResult";
import OutputDemping from "./components/OutputDemping";
import ProductChangeHistory from "./components/ProductChangeHistory";
import ProductApproveForSale from "./components/ProductApproveForSale";
import ProductWithdrawFromSale from "./components/ProductWithdrawFromSale";
import ProductMerchant from "./components/ProductMerchant";
import ProductInCity from "./components/ProductInCity";
const { Panel } = Collapse;

const StoreProducts = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const storeId = params.id;
  console.log(storeId)
  const [uploadLimit, setUploadLimit] = useState(null);
  const [products, setProducts] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [storeInfo, setStoreInfo] = useState({});
  const [store, setStore] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [limit, setLimit] = useState(searchParams.get("limit") || 10);
  const [query, setQuery] = useState(searchParams.get("q") || "");
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [filter, setFilter] = useState(searchParams.get("filter") || "active");
  const { Search } = Input;

  useEffect(() => {
    fetchProducts();
    fetchStore();
    fetchUploadLimit();
  }, [storeId, currentPage, limit]);

  const fetchProducts = () => {
    setIsLoading(true);

    axios
      .get(
        `${config.service}/api/product/${storeId}?q=${query}&limit=${limit}&page=${currentPage}&filter=${filter}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        setProducts(data.data.data);
        setTotalCount(data.data.total);
        setStoreInfo(data.data.storeInfo);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchStore = () => {
    axios
      .get(`${config.service}/api/store/${storeId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
        },
      })
      .then((data) => {
        setStore(data.data);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          clearToken();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchUploadLimit = () => {
        axios
            .get(`${config.service}/api/kaspi-store-upload-limit/${storeId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                },
            })
            .then((response) => {
                console.log(response.data)
                setUploadLimit(response.data);  // сохраняем данные лимита в state
            })
            .catch((err) => {
                console.error("Error fetching upload limit:", err);
                if (err?.response?.status === 401) {
                    clearToken();  // очистка токена, если статус 401
                }
            })
            .finally(() => {
                setIsLoading(false);  // завершение загрузки
            });
    };

  const loadCityData = () => {
    axios
      .post(
        `${config.service}/api/store/${storeId}/load-products-city-data`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        const msg = data.data?.message;

        if (msg) {
          message.success(msg);
        }
      });
  };

  const loadProducts = () => {
    setIsLoadingProducts(true);

    axios
      .post(
        `${config.service}/api/store/${storeId}/load`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "admin_access_token"
            )}`,
          },
        }
      )
      .then((data) => {
        const msg = data.data?.message;

        if (msg) {
          message.success(msg);
        }
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  };

  const handleSearch = () => {
    navigate({
      pathname: "",
      search: createSearchParams({
        page: currentPage,
        q: query || "",
        limit,
        filter,
      }).toString(),
    });

    fetchProducts();
  };

  const filterOptions = [
    {
      value: "active",
      label: "Опубликованные",
    },
    {
      value: "first",
      label: "На первом месте",
    },
    {
      value: "on",
      label: "Автоснижение вкл.",
    },
    {
      value: "off",
      label: "Автоснижение выкл.",
    },
    {
      value: "archive",
      label: "В архиве",
    },
    {
      value: "no-competitors",
      label: "Нет конкурентов",
    },
    {
      value: "waiting",
      label: "В очереди",
    },
  ];

  const getSearchForm = () => {
    return (
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <div
          style={{
            display: "flex",
            minWidth: 300,
            maxWidth: 500,
            width: "100%",
          }}
        >
          <Search
            placeholder="Поиск по ключевому слову"
            allowClear
            enterButton="Поиск"
            size="large"
            disabled={isLoading}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
            onSearch={handleSearch}
          />
        </div>
        <div className="w-50 mt-3">
          <Select
            loading={isLoading}
            disabled={isLoading}
            value={filter}
            style={{ minWidth: 200, maxWidth: 300, width: "100%" }}
            options={filterOptions}
            onChange={(v) => {
              navigate({
                pathname: "",
                search: createSearchParams({
                  page: 1,
                  q: query || "",
                  limit,
                  filter: v,
                }).toString(),
              });

              setFilter(v);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>
    );
  };

  const getPagination = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 15,
        }}
      >
        <Pagination
          current={currentPage}
          total={totalCount}
          disabled={isLoading}
          onChange={(value) => {
            if (value === currentPage) {
              return;
            }

            setCurrentPage(value);

            navigate({
              pathname: "",
              search: createSearchParams({
                page: value,
                q: query || "",
                limit,
                filter,
              }).toString(),
            });
          }}
          defaultCurrent={currentPage}
          showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
          onShowSizeChange={async (current, size) => {
            if (size === limit) {
              return;
            }

            setLimit(size);

            navigate({
              pathname: "",
              search: createSearchParams({
                page: currentPage,
                q: query || "",
                limit: size,
                filter,
              }).toString(),
            });
          }}
          pageSize={limit}
          defaultPageSize={limit}
        />
      </div>
    );
  };

  return (
    <Card className="mx-3">
      <PageHeader
        className="site-page-header"
        title="Товары магазина"
        onBack={() => {
          window.location.href = `/store/${storeId}`;
        }}
      />

      <div
        style={{
          marginBottom: 15,
        }}
      >
        <Collapse className="mb-3" defaultActiveKey={["store-info"]}>
          <Panel
            header={storeInfo?.name || "Информация о магазине"}
            key="store-info"
          >
            <ProductStoreInfo storeInfo={storeInfo} store={store} uploadLimit={uploadLimit} />
          </Panel>
        </Collapse>

        <Button
          type="primary"
          onClick={loadProducts}
          disabled={isLoadingProducts}
        >
          Обновить продукты
        </Button>
      </div>

      {getSearchForm()}

      <ShowProducts
        products={products}
        isLoading={isLoading}
        store={storeInfo}
      />

      {getPagination()}
    </Card>
  );
};

const ShowProducts = ({ products, isLoading, store }) => {
  const columns = [
    {
      dataIndex: "status",
      key: "status",
      title: "",
    },
    {
      title: "",
      dataIndex: "photo",
      key: "photo",
    },
    {
      title: "",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      dataIndex: "mainCityPrice",
      key: "mainCityPrice",
      width: 220,
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
    },
  ];
  const data = [];
  let key = 1;
  for (let product of products) {
    data.push({
      id: product._id,
      key,
      data: product,
      photo: (
        <div
          style={{
            width: 100,
            height: 100,
          }}
        >
          <img
            style={{
              width: 100,
              maxHeight: "100%",
              objectFit: "contain",
            }}
            src={product.img}
          />
        </div>
      ),
      description: (
        <div>
          <div>
            <a
              href={product.url}
              target="_blank"
              style={{
                fontSize: 12,
              }}
            >
              {product.name}
            </a>
          </div>
          <div
            style={{
              marginTop: 10,
              fontSize: 11,
              color: "gray",
            }}
          >
            {product.sku}
          </div>
          <div
            style={{
              marginTop: 10,
              fontSize: 11,
              color: "gray",
            }}
          >
            {product.masterProductSku}
          </div>
        </div>
      ),
      mainCityPrice: (
        <div
          style={{
            fontSize: 12,
          }}
        >
          <div>Цена на&nbsp;Kaspi: {currencyFormat(product.price)}</div>
          <div>Мин.&nbsp;цена: {currencyFormat(product.availableMinPrice)}</div>
          <div>
            Макс.&nbsp;цена: {currencyFormat(product.availableMaxPrice)}
          </div>
        </div>
      ),
      edit: (
        <div>
          <div>
            <Checkbox checked={product.isDemping} disabled={true}>
              Автоснижение
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={product.isAutoRaise && product.isDemping}
              disabled={true}
            >
              Автоподнятие
            </Checkbox>
          </div>
        </div>
      ),
      amount: (
        <div>
          <Form.Item label="Количество">{product.amount}</Form.Item>
          <Checkbox checked={product.isWithdrawFromSale} disabled={true}>
            Cнять&nbsp;с&nbsp;продажи
          </Checkbox>
        </div>
      ),
      storeId: product.storeId,
      sku: product.sku,
      status: getProductStatus(product),
    });

    key++;
  }

  return (
    <Table
      size="small"
      bordered
      columns={columns}
      loading={isLoading}
      dataSource={data}
      pagination={false}
      expandable={{
        expandedRowRender: (record) => {
          return <ProductInfoOption product={record} store={store} />;
        },
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}
    />
  );
};

const getProductStatus = (product) => {
  const getPlace = () => {
    if (product.place && product.isActive) {
      return (
        <div>
          <Tooltip
            title={`Позиция товара:  ${
              product.place < 10 ? product.place : "10+"
            }`}
          >
            <span
              className={`border px-1 rounded ${
                product.place === 1 ? "text-success" : "text-secondary"
              }`}
              style={{
                cursor: "default",
              }}
            >
              {product.place < 10 ? product.place : "10+"}
            </span>
          </Tooltip>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getIsChanging = () => {
    if (product.isChanging && product.isActive) {
      return (
        <div>
          <Tooltip title="Применение изменений занимает порядка 30-50 минут">
            <span>
              <LoadingOutlined />
            </span>
          </Tooltip>
        </div>
      );
    }
  };

  const getDempOff = () => {
    if (!product.isDemping && product.isActive) {
      return (
        <div>
          <Tooltip title={`Автоснижение цены отключена`}>
            <span
              className={`border px-1 rounded text-danger`}
              style={{
                cursor: "default",
              }}
            >
              <WarningOutlined />
            </span>
          </Tooltip>
        </div>
      );
    }
  };

  const getInMinus = () => {
    if (product.inMinus && product.isActive) {
      return (
        <div>
          <Tooltip title={`Цена дошла до минимальной цены`}>
            <span
              className={`border px-1 rounded text-warning`}
              style={{
                cursor: "default",
              }}
            >
              <WarningOutlined />
            </span>
          </Tooltip>
        </div>
      );
    }
  };

  const getArchive = () => {
    if (!product.isActive) {
      return (
        <div>
          <Tooltip title="Товар в архиве">
            <span className={`border px-1 rounded`}>
              <InboxOutlined />
            </span>
          </Tooltip>
        </div>
      );
    }
  };

  return (
    <div className="d-flex flex-column align-items-center">
      {getArchive()}
      {getPlace()}
      {getIsChanging()}
      {getDempOff()}
      {getInMinus()}
    </div>
  );
};

const ProductInfoOption = ({ product, store }) => {
  const options = [
    { value: "Demping", label: "Автоснижение" },
    { value: "Orders", label: "Заказы" },
  ];

  const [selectedOption, setSelectedOption] = useState(options[0].value);

  const getSelectedOption = (value) => {
    setSelectedOption(value);
  };

  return (
    <div>
      <Segmented
        onChange={getSelectedOption}
        options={options}
        block
        size="large"
      />
      {selectedOption === "Demping" ? (
        <ProductInfoList product={product} store={store} />
      ) : (
        <ProductOrders product={product} />
      )}
    </div>
  );
};

const role = localStorage.getItem("role") || "";

const ProductInfoList = ({ product, store }) => {
  return (
    <Card>
      <Collapse>
        <Panel header="Настройки автоснижения">
          <ProductCities product={product.data} />
        </Panel>
        {(role === "SUPER_ADMIN" ||
          role === "DEVELOPER" ||
          role === "MANAGER") && (
          <Panel header="ProductMerchant">
            <ProductMerchant store={store} product={product.data} />
          </Panel>
        )}
        <Panel header="Настройки автопринятия">
          <AutoAcceptOrderSettings product={product.data} />
        </Panel>
        <Panel header="Остатки">
          <ProductRemains product={product.data} />
        </Panel>
        <Panel header="История изменение цены">
          <PriceHistory product={product} />
        </Panel>
        <Panel header="История запросов">
          <ChangePriceRequestResult product={product} />
        </Panel>
        <Panel header="История проверок">
          <OutputDemping product={product} />
        </Panel>
        <Panel header="История изменения товара">
          <ProductChangeHistory product={product} />
        </Panel>
        <Panel header="История выставление товара">
          <ProductApproveForSale product={product} />
        </Panel>
        <Panel header="История снятие товара">
          <ProductWithdrawFromSale product={product} />
        </Panel>
        <Panel header="Статусы в городах">
          <ProductInCity productId={product.id} />
        </Panel>
      </Collapse>
    </Card>
  );
};

export default StoreProducts;
