import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { currencyFormat } from "../../../scripts/format";
import { fetchEveryWeekStats } from "../utils/fetch";
import { ActivatedUserStats } from "./ActivatedUserStats";
import { Spin } from "antd";

export const EveryWeekStats = () => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    fetchEveryWeekStats(
      (data) => {
        setData(data);
      },
      () => {},
      () => {
        setIsLoading(false);
      }
    );
  }, []);

  const getMonths = () => {
    const keys = Object.keys(data?.month || {});

    const result = [];

    for (let key of keys) {
      result.push(data.month[key]);
    }

    return result;
  };

  return (
    <div>
      {/* <h2 className="mb-3 pb-3 border-bottom">По недельняя статистика</h2> */}
      {isLoading && (
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{ height: 300 }}
        >
          <Spin />
        </div>
      )}
      {!isLoading && (
        <div>
          {getMonths().map((month, index) => {
            return (
              <Month
                key={index}
                month={month}
                minValue={data?.minValue}
                maxValue={data?.maxValue}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

const Month = ({ month, minValue, maxValue }) => {
  const getWeeks = () => {
    const keys = Object.keys(month?.weeks || {});

    const result = [];

    for (let key of keys) {
      result.push(month.weeks[key]);
    }

    return result;
  };

  return (
    <div style={{ borderBottom: "1px solid lightgray", margin: "20px 0", width: "100%" , overflow: 'auto'}}>
        <div style={{ display: 'flex', width: 1200 }}>
          <h3 style={{ }}>
            {month.monthName} {month.year} - {month.count} ({currencyFormat(month.totalPrice)})
          </h3>
          <ActivatedUserStats month={month} />
          <div style={{fontSize:"0.6875rem"}}>
            Бот: {currencyFormat(month.subscriptionPrice)} ({parseFloat(month.subscriptionPrice / month.totalPrice * 100).toPrecision(3)}%)
            Аналитика: {currencyFormat(month.analyticsPrice)} ({parseFloat(month.analyticsPrice / month.totalPrice * 100).toPrecision(3)}%)
            Whatsapp сервис: {currencyFormat(month.whatsappServicePrice)} ({parseFloat(month.whatsappServicePrice / month.totalPrice * 100).toPrecision(3)}%) 
          </div>
        </div>

        <div style={{ width: 350, height: 180, display: "flex" }}>
          {getWeeks().map((week, index) => {
            return (
              <Week
                key={index}
                week={week}
                maxValue={maxValue}
                minValue={minValue}
              />
            );
          })}
      </div>
    </div>
  );
};

const Week = ({ week, minValue, maxValue }) => {
  const labels = [];
  const datasets = [];
  const newPayments = [];

  const getDays = () => {
    const keys = Object.keys(week?.days || {});

    for (let key of keys) {
      labels.push(key.split("-")[3]);
      datasets.push(week.days[key].totalPrice);
      newPayments.push(week.days[key].newTotalPrice);
    }
  };

  getDays();

  const state = {
    labels,
    datasets: [
      {
        data: datasets,
        borderColor: "blue",
        pointRadius: 5,
      },
      {
        data: newPayments,
        borderColor: "green",
        pointRadius: 5,
      },
    ],
  };

  console.log(newPayments);

  return (
    <div style={{ marginRight: 15, marginBottom: 30 }}>
      <Line
        data={state}
        options={{
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            yAxis: {
              min: 0,
              max: 3116000,
            },
          },
        }}
      />
      <div>
        {week.count} - ({currencyFormat(week.totalPrice)})
      </div>
    </div>
  );
};

const Day = ({ day }) => {};
