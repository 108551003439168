import { Layout, Menu } from "antd";
import {
  PieChartOutlined,
  UserOutlined,
  UnorderedListOutlined,
  HomeOutlined,
  DollarCircleOutlined,
  ControlOutlined,
  LogoutOutlined,
  UserAddOutlined,
  BarChartOutlined,
  LineChartOutlined,
  ForkOutlined,
  MessageOutlined,
  BankOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import articleLogo from "../../assets/icons/article.png";

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);

  const theme = "light";

  const getSelectedKey = () => {
    const url = new URL(window.location.href);
    const { pathname } = url;

    if (pathname === "/store-product-position-analytics") {
      return "store-product-position-analytics";
    } else if (pathname === "/potential-client") {
      return "potential-client";
    } else if (pathname.includes("store")) {
      return "store";
    } else if (pathname.includes("client")) {
      return "client";
    } else if (pathname === "/dashboard") {
      return "dashboard";
    } else if (pathname.includes("whatsapp")) {
      return "whatsapp";
    } else if (pathname === "/budget") {
      return "budget";
    } else if (pathname === "/proxy") {
      return "proxy";
    } else if (pathname.includes("guide")) {
      return "guide";
    } else if (pathname.includes("chat")) {
      return "chat";
    }

    return "home";
  };

  if (getSelectedKey() === "whatsapp") {
    return <></>;
  }

  const isSuperAdmin = () => {
    return (localStorage.getItem("role") === "SUPER_ADMIN");
  };
  const isManager = () => {
    return localStorage.getItem("role") === "MANAGER";
  };
  const isSalesDepartment = () => {
    return localStorage.getItem("role") === "SALES_DEPARTMENT";
  };
  const isTargetologist = () => {
    return localStorage.getItem("role") === "TARGETOLOGIST";
  };
  const isInternDeveloper = () => {
    return localStorage.getItem("role") === "INTERN_DEVELOPER";
  };
  const isDeveloper = () => {
    return localStorage.getItem("role") === "DEVELOPER";
  };
  const isMarketingManager = () => {
    return localStorage.getItem("role") === "MARKETING_MANAGER";
  };

  return (
    <Layout.Sider
      theme={theme}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => {
        setCollapsed(value);
      }}
      className="layout-sidebar"
      breakpoint="lg"
    >
      <Menu
        theme={theme}
        defaultSelectedKeys={[getSelectedKey()]}
        style={{
          marginTop: 30,
        }}
      >
        {(isSuperAdmin() || isManager() || isMarketingManager()) && (
          <Menu.Item
            key="home"
            icon={<HomeOutlined />}
            style={{
              padding: "0 30px",
            }}
          >
            <Link to="/">Главная</Link>
          </Menu.Item>
        )}
        {/* <> */}
        {(isSuperAdmin() || isMarketingManager()) && (
          <Menu.Item
            key="analytics"
            icon={<BarChartOutlined />}
            style={{
              padding: "0 30px",
            }}
          >
            <Link to="/analytics">Аналитика</Link>
          </Menu.Item>
        )}
        {(isSuperAdmin() || isManager() || isDeveloper()) && (
          <Menu.Item
            key="system-health-dashboard"
            icon={<BarChartOutlined />}
            style={{
              padding: "0 30px",
            }}
          >
            <Link to="/system-health-dashboard">Состояния системы</Link>
          </Menu.Item>
        )}
        {/* </> */}

        {(isSuperAdmin() || isManager() || isMarketingManager()) && (
          <>
            <Menu.Item
              key="budget"
              icon={<DollarCircleOutlined />}
              style={{
                padding: "0 30px",
              }}
            >
              <Link to="/budget">Бюджет</Link>
            </Menu.Item>
          </>
        )}
        {(isSuperAdmin() || isInternDeveloper() || isDeveloper()) && (
          <>
            <Menu.Item
              key="proxy"
              icon={<ControlOutlined />}
              style={{
                padding: "0 30px",
              }}
            >
              <Link to="/proxy">Прокси</Link>
            </Menu.Item>
          </>
        )}
        {(isSuperAdmin() || isTargetologist() || isMarketingManager()) && (
          <Menu.Item
            key="dashboard"
            icon={<PieChartOutlined />}
            style={{
              padding: "0 30px",
            }}
          >
            <Link to="/dashboard">Дашборд</Link>
          </Menu.Item>
        )}
        {(isSuperAdmin() || isManager() || isDeveloper()) && (
          <>
            <Menu.Item
              key="client"
              icon={<UserOutlined />}
              style={{
                padding: "0 30px",
              }}
            >
              <Link to="/client">Клиенты</Link>
            </Menu.Item>
            <Menu.Item
              key="store"
              icon={<UnorderedListOutlined />}
              style={{
                padding: "0 30px",
              }}
            >
              <Link to="/store">Магазины</Link>
            </Menu.Item>
          </>
        )}
        {(isSuperAdmin() || isManager() || isSalesDepartment()) && (
          <Menu.Item
            key="potential-client"
            icon={<UserAddOutlined />}
            style={{
              padding: "0 30px",
            }}
          >
            <Link to="/potential-client">ОП</Link>
          </Menu.Item>
        )}
        {(isSuperAdmin() ||
          isManager() ||
          isSalesDepartment() ||
          isDeveloper()) && (
          <Menu.Item
            key="store-product-position-analytics"
            icon={<LineChartOutlined />}
            style={{
              padding: "0 30px",
            }}
          >
            <Link to="/store-product-position-analytics">
              Аналитика магазина
            </Link>
          </Menu.Item>
        )}
        {isSuperAdmin() && (
          <Menu.Item
            key="product-mapping"
            icon={<ForkOutlined />}
            style={{ padding: "0 30px" }}
          >
            <Link to="/product-mapping">Продукт Mapping</Link>
          </Menu.Item>
        )}
        {isSuperAdmin() && (
            <Menu.Item
              key="mapping-dashboard"
              icon={<PieChartOutlined/>}
              style={{ padding: "0 30px" }}
            >
              <Link to="/mapping-dashboard">Mapping Dashboard</Link>
            </Menu.Item>
        )
        }
         {(isSuperAdmin() || isManager()) && (
  
<Menu.Item
  key="article-editor"
  icon={<img src={articleLogo} alt="Article Editor" style={{ width: 15, height: 15 }} />}
  style={{ padding: "0 30px" }}
>
<Link to="/article-editor" onClick={()=>{setCollapsed(true)}}>Article Editor</Link>
</Menu.Item>
)}
        {(isSuperAdmin() || isManager()) && (
          <Menu.Item
            key="chat"
            icon={<MessageOutlined />}
            style={{ padding: "0 30px" }}
          >
            <Link to="/chat" onClick={()=>{setCollapsed(true)}}>WhatsApp Chat</Link>
          </Menu.Item>
        )}
        {isDeveloper() && (
          <Menu.Item
            key="auction"
            icon={<BankOutlined />}
            style={{ padding: "0 30px" }}
          >
            <Link to="/auction">Аукцион</Link>
          </Menu.Item>
        )}
        {/* <Menu.Item
          key="guide"
          icon={<QuestionCircleOutlined />}
          style={{
            padding: "0 30px",
          }}
        >
          <Link to="/guide">SaleScout Гид</Link>
        </Menu.Item> */}
        {/* {isSuperAdmin() && (
          <Menu.Item
            key="notification"
            icon={<BellOutlined />}
            style={{
              padding: "0 30px",
            }}
          >
            <Link to="/notification">Уведомления</Link>
          </Menu.Item>
        )} */}
        <Menu.Item
          key="logout"
          icon={<LogoutOutlined />}
          style={{
            padding: "0 30px",
          }}
          className="text-danger"
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          Выйти
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
