import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../config.json"; // Ваш config с сервисами
import { clearToken } from "../../scripts/token"; // Ваш скрипт для очистки токена
import { Button, Input, Radio } from "antd";

const LoadFromAnotherService = () => {
    const params = useParams();
    const merchantId = params.id; // получаем merchantId из URL
    const [uploadLimit, setUploadLimit] = useState(null);
    // const [isLoading, setIsLoading] = useState(true);
    const [serviceName, setServiceName] = useState("algatop")
    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [status, setStatus] = useState("")

    useEffect(() => {
        // fetchUploadLimit();  // получаем данные лимита загрузки при монтировании компонента
    }, [merchantId]);

    const startGettingLoadingStatus = async () => {
        setInterval(async () => {
            const response = await axios.get(`${config.service}/api/store/get-loading-data-from-another-service-status/${merchantId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                },
            })
    
            console.log(response.data)
            setStatus(response.data.status)
        }, 5000)
        
    }

    // Получаем лимит загрузки по merchantId
    const startLoading = () => {
        axios
            .post(`${config.service}/api/store/start-loading-from-another-service/${merchantId}`,
                {
                    serviceName,
                    login,
                    password,
                },
                {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("admin_access_token")}`,
                },
            })
            .then((response) => {
                console.log(response)
                // setUploadLimit(response.data);  // сохраняем данные лимита в state
            })
            .catch((err) => {
                console.error("Error fetching upload limit:", err);
                if (err?.response?.status === 401) {
                    clearToken();  // очистка токена, если статус 401
                }
            })
            .finally(() => {
                startGettingLoadingStatus()
                // setIsLoading(false);  // завершение загрузки
            });
    };

    return (
        <div style={{marginTop: "20px"}}>
            {/* {isLoading ? (
                <p>Loading...</p>
            ) : ( */}
                <div>
                    <h3>Загрузить данные из другого бота</h3>
                    
                    <Radio.Group onChange={(e) => setServiceName(e.target.value)} value={serviceName} style={{marginTop: "7px"}}>
                        <Radio value="algatop">Algatop</Radio>
                        <Radio value="demetra">Demetra</Radio>
                    </Radio.Group>

                    <Input type="text" placeholder="Логин" onChange={(e) => setLogin(e.target.value)} style={{marginTop: "7px"}}/>
                    <Input type="text" placeholder="Пароль" onChange={(e) => setPassword(e.target.value)} style={{marginTop: "7px"}}/>

                    <Button type="primary" onClick={startLoading} style={{marginTop: "7px"}}>Перенести данные</Button>
                    <p>{status}</p>
                </div>
            {/* )} */}
        </div>
    );
};

export default LoadFromAnotherService;
